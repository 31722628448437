<template>
  <div class="container">
    <div class="header"><a  @click="goback"><img src="../assets/images/back.png" alt=""></a> 关于我们 </div>
    <div class="blian22"></div>
    <div v-html="aboutInfo.content"></div>
  </div>
</template>
<script>
	
	import { about } from "@/assets/js/api";
export default {
  name: "myactivity",
  data() {
    return {
    	aboutInfo:{},
    }
  },
  methods: {
  	goback: function() {
				this.$router.go(-1)
			},
  	//关于我们的信息
			getabout(){
				about({}).then(res => {
					if(res.code == 0) {
						console.log(res)
						this.aboutInfo = res.about;
					}
				});
			}
  },
  mounted() {
  	this.getabout()
  }
};
</script>

