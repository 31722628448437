<template>
	<div class="container">
		<div class="container">
			<div class="downcont-main">
				<h3>方法一:发送至邮箱</h3>
				<p>邮箱中点击链接下载</p>
				<div class="dcm-http flex_outside">
					<span class="send" @click="sendOut">发送</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import  Email  from "@/assets/js/smtp.js";
	export default {
		name: "downcont",
		data() {
			return {

			};
		},
		methods: {
			// 发送
			sendOut() {
				console.log(Email)
				Email.send({
				    Host : "smtp.163.com",
				    Username : "qq2671771031@163.com",
				    Password : "AIQBHXXXLNVPWMUO",
				    To : '1985894120@qq.com',
				    From : "qq2671771031@163.com",
				    Subject : "测试案例",
				    Body : "收到请回复"
				}).then(
				  message => alert(message)
				);
			}
		}
	};
</script>
<style>
	.mailbox {
		/* width: 72%; */
		width: 100%;
		/* margin-right: 0.5rem; */
		border: 1px solid #DDDDDD;
		border-right: none;
		/* border-radius: 5px; */
		font-size: 0.7rem;
	}

	.send {
		background: #40A5FF;
		height: 1.8rem;
		line-height: 1.8rem;
		/* border-radius: 5px; */
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		width: 4rem;
		text-align: center;
		font-size: 0.7rem;
		color: #fff;
	}
</style>
